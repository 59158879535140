<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 提供服务 -->
      <ServiceList title='提供服务' :list='list' />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc5_0.jpg'),
        //   title: 'BLM工程',
        //   intro: '用真心换诚信 优质服务请放心'
        // },
      ],
      info: {
        title: 'BIM工程',
        desc: 'BIM技术被广泛应用于建筑信息建模、碰撞检测、工程量统计、施工进度管理、设施管理等方面。通过使用BIM技术，可以对建筑进行全面的数字化建模，实现各个阶段的数据共享和协同设计，避免传统工程中可能出现的信息断层和重复工作。同时，BLM工程服务还可以利用BIM技术进行智能化管理，如自动化生成施工图纸、进行施工过程模拟和优化、进行设备维护和能源管理等，从而提高工作效率和管理水平。',
        list: [
          { id: 1, title: '需求分析' },
          { id: 2, title: '设计阶段' },
          { id: 3, title: '施工准备' },
          { id: 4, title: '施工阶段' },
          { id: 5, title: '验收阶段' },
          { id: 6, title: '维护阶段' },
        ]
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/svc5_1.jpg'),
          tit: '提高项目中标率',
          desc: '利用BIM技术，企业可以更立体地展现技术方案及实力，更精确快捷地制定投标价，从而提高企业的中标率。'
        }, {
          id: 2,
          img: require('@/assets/images/svc5_2.jpg'),
          tit: '增强企业技术实力',
          desc: '通过BIM技术，企业可以更好地进行项目设计和规划，提高企业的技术实力和竞争力。'
        }, {
          id: 3,
          img: require('@/assets/images/svc5_3.jpg'),
          tit: '降低项目成本',
          desc: 'BIM技术可以降低误差，减少施工成本，提高施工质量和效率，从而降低整个项目的成本。'
        }, {
          id: 4,
          img: require('@/assets/images/svc5_4.jpg'),
          tit: '缩短项目周期',
          desc: '通过BIM技术，企业可以更好地协调各专业之间的合作和交流，提高工作效率和协同性。'
        }, {
          id: 5,
          img: require('@/assets/images/svc5_5.jpg'),
          tit: '增强绿色建筑性能',
          desc: '通过BIM技术，企业可以更好地进行绿色建筑分析和评估，提高建筑的环保性能和可持续性。'
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>